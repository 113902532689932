.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.url-text-box {
  width: 50%;
  height: 50px;
  font-size:25pt;
  border-radius: 25px;
  border: 2px solid #6c6c6c;
  text-align: center;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  flex-direction: column;
}

body{
  background:#fff;
  color:#000;
  font-family:Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size:16px;
  line-height:28px;
  margin:0
}
h1,h2,h3,h4,h5,h6,li,p{
  margin:0 0 16px
}
h1{
  font-size:40px;
  line-height:60px
}
h1,h2{
  font-weight:700
}
h2{
  font-size:32px;
  line-height:48px
}
h3{
  font-size:24px;
  line-height:36px
}
h3,h4{
  font-weight:700
}
h4{
  font-size:20px;
  line-height:30px
}
h5,h6{
  font-size:16px;
  line-height:24px;
  font-weight:700
}
a{
  text-decoration:none;
  cursor:pointer;
  color:#000
}
a:hover,a[rel~=nofollow]{
  text-decoration:underline
}
a[rel~=nofollow]{
  color:#553df4
}
a[rel~=nofollow]:hover{
  text-decoration:none
}
.visible{
  display:block
}
.hidden{
  display:none
}
.page{
  width:100%
}
.container{
  position:relative;
  width:90%;
  max-width:1024px;
  margin:0 auto
}
.header{
  padding:16px 0
}
.header .title{
  font-size:28px;
  line-height:42px;
  font-weight:700
}
.translations-list-container{
  padding-bottom:8px;
  margin:0 0 16px
}
.translations-list-container .translations-list{
  margin:0;
  padding:0;
  list-style:none
}
.translations-list-container .translations-list .translations-list-item{
  display:inline-block;
  padding:0;
  margin:0 8px 8px 0;
  font-weight:700;
  color:#553df4
}
.translations-list-container .translations-list .translations-list-item a{
  display:inline-block;
  color:#553df4;
  border:1px solid #553df4;
  border-radius:32px;
  padding:4px 16px
}
.translations-content-container{
  padding-top:16px;
  border-top:1px solid #eee;
  word-break:break-all
}
.footer{
  border-top:1px solid #eee;
  margin:32px 0 0;
  padding:16px 0
}
